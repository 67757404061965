
import { Building, Key } from "@/interfaces";
import Vue, { PropType } from "vue";

import { mdiPlus, mdiDelete, mdiDotsVertical, mdiPencil } from "@mdi/js";
import http from "@/http";

export default Vue.extend({
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  computed: {
    icon() {
      return {
        mdiPlus,
        mdiDelete,
        mdiDotsVertical,
        mdiPencil,
      };
    },
  },
  methods: {},
});
